import { OAuthType } from './types/requests';
import { SegmentCondition } from './types/responses';

const keywords = {
    URL_CLIENT: 'https://cdn.jsdelivr.net/npm/flagsmith/index.js',
    LIB_NAME: 'flagsmith',
    LIB_NAME_JAVA: 'FlagsmithClient',
    NPM_CLIENT: 'flagsmith',
    NPM_RN_CLIENT: 'react-native-flagsmith',
    NPM_NODE_CLIENT: 'flagsmith-nodejs',
    USER_ID: 'user_123456',
    FEATURE_FUNCTION: 'myCoolFeature',
    SEGMENT_NAME: 'superUsers',
    FEATURE_NAME: 'my_cool_feature',
    TRAIT_NAME: 'age',
    USER_FEATURE_FUNCTION: 'myEvenCoolerFeature',
    USER_FEATURE_NAME: 'my_even_cooler_feature',
    FEATURE_NAME_ALT: 'banner_size',
    FEATURE_NAME_ALT_VALUE: 'big',
};

export default {
    tagColors: [
        '#3d4db6',
        '#ea5a45',
        '#f1d502',
        '#60bd4e',
        '#fe5505',
        '#1492f4',
        '#14c0f4',
        '#c277e0',
        '#039587',
        '#344562',
        '#ffa500',
        '#3cb371',
        '#d3d3d3',
        '#5D6D7E',
        '#641E16',
        '#5B2C6F',
        '#D35400',
        '#F08080',
        '#AAC200',
        '#DE3163',
    ],
    forms: {
        maxLength: {
            'FEATURE_ID': 150,
            'SEGMENT_ID': 150,
            'TRAITS_ID': 150,
        },
    },
    defaultRule: {
        property: '',
        operator: 'EQUAL',
        value: '',
    } as SegmentCondition,
    events: {
        'CREATE_ENVIRONMENT': { 'event': 'Environment created', 'category': 'Environment' },
        'CREATE_FEATURE': { 'event': 'Feature created', 'category': 'Features' },
        'CREATE_FIRST_FEATURE': { 'event': 'First Feature created', 'category': 'First' },
        'CREATE_SEGMENT': { 'event': 'Segment created', 'category': 'Segments' },
        'CREATE_FIRST_SEGMENT': { 'event': 'First Segment created', 'category': 'First' },
        'CREATE_ORGANISATION': { 'event': 'Organisation created', 'category': 'Organisation' },
        'CREATE_FIRST_ORGANISATION': { 'event': 'First Organisation created', 'category': 'First' },
        'REFERRER_CONVERSION': (referrer:string) => ({ 'event': `${referrer} converted`, 'category': 'Referrer' }),
        'REFERRER_REGISTERED': (referrer:string) => ({ 'event': `${referrer} registered`, 'category': 'Referrer' }),
        'CREATE_PROJECT': { 'event': 'Project created', 'category': 'Project' },
        'CREATE_FIRST_PROJECT': { 'event': 'First Project created', 'category': 'First' },
        'CREATE_USER_FEATURE': { 'event': 'User feature created', 'category': 'User Features' },
        'DELETE_INVITE': { 'event': 'Invite deleted', 'category': 'Invite' },
        'CREATE_GROUP': { 'event': 'Group created', 'category': 'Group' },
        'DELETE_GROUP': { 'event': 'Group deleted', 'category': 'Group' },
        'DELETE_USER': { 'event': 'User deleted', 'category': 'Organisation' },
        'DELETE_ORGANISATION': { 'event': 'Organisation deleted', 'category': 'Organisation' },
        'DEMO_ACCOUNT': { 'event': 'User demo login', 'category': 'User' },
        'EDIT_ENVIRONMENT': { 'event': 'Environment edited', 'category': 'Environment' },
        'EDIT_FEATURE': { 'event': 'Feature edited', 'category': 'Features' },
        'EDIT_ORGANISATION': { 'event': 'Organisation edited', 'category': 'Organisation' },
        'EDIT_PROJECT': { 'event': 'Project edited', 'category': 'Project' },
        'EDIT_USER_FEATURE': { 'event': 'User feature edited', 'category': 'Features' },
        'INVITE': { 'event': 'Invite sent', 'category': 'Invite' },
        'ACCEPT_INVITE': (org:any) => ({ 'event': 'Invite accepted', 'category': 'Invite', extra: org }),
        'LOGIN_DEMO': { 'event': 'User demo login', 'category': 'User' },
        'LOGIN': { 'event': 'User login', 'category': 'User' },
        'OAUTH': (type:OAuthType) => ({ 'event': `User oauth ${type}`, 'category': 'User' }),
        'REGISTER': { 'event': 'User register', 'category': 'User' },
        'REMOVE_ENVIRONMENT': { 'event': 'Environment edited', 'category': 'Environment' },
        'REMOVE_FEATURE': { 'event': 'Feature removed', 'category': 'Features' },
        'REMOVE_PROJECT': { 'event': 'Project removed', 'category': 'Project' },
        'REMOVE_USER_FEATURE': { 'event': 'User feature removed', 'category': 'User Features' },
        'RESEND_INVITE': { 'event': 'Invite resent', 'category': 'Invite' },
        'TOGGLE_FEATURE': { 'event': 'Feature toggled', 'category': 'Features' },
        'TOGGLE_USER_FEATURE': { 'event': 'User feature toggled', 'category': 'User Features' },
        'TRY_IT': { 'event': 'Try it clicked', 'category': 'TryIt' },
        'VIEW_USER_FEATURE': { 'event': 'User feature viewed', 'category': 'User Features' },
        'VIEW_FEATURE': { 'event': 'Feature viewed', 'category': 'Features' },
        'VIEW_SEGMENT': { 'event': 'Segment viewed', 'category': 'Segment' },
        'UPDATE_USER_ROLE': { 'event': 'Updated user role', 'category': 'Organisation' },
    },
    modals: {
        'PAYMENT': 'Payment Modal',
    },
    projectPermissions: (perm:string) => `To use this feature you need the <i>${perm}</i> permission for this project.<br/>Please contact a member of this project who has administrator privileges.`,
    environmentPermissions: (perm:string) => `To manage this feature you need the <i>${perm}</i> permission for this environment.<br/>Please contact a member of this environment who has administrator privileges.`,
    pages: {
        'ACCOUNT': 'Account Page',
        'AUDIT_LOG': 'Audit Log Page',
        'WHAT_ARE_FEATURE_FLAGS': 'What are feature flags Page',
        'RESET_PASSWORD': 'Reset Password Page',
        'INTEGRATIONS': 'Integrations Page',
        'COMING_SOON': 'Coming Soon Page',
        'CREATE_ENVIRONMENT': 'Create Environment Page',
        'DOCUMENTATION': 'Documentation Page',
        'ENVIRONMENT_SETTINGS': 'Environment Settings Page',
        'FEATURES': 'Features Page',
        'HOME': 'Home Page',
        'INVITE': 'User Invited Page',
        'NOT_FOUND': '404 Page',
        'ORGANISATION_SETTINGS': 'Organisation Settings Page',
        'POLICIES': 'Terms & Policies Page',
        'PRICING': 'Pricing Page',
        'PROJECT_SELECT': 'Project Select Page',
        'PROJECT_SETTINGS': 'Project Settings Page',
        'USER': 'User Page',
        'USERS': 'Users Page',
    },
    strings: {
        TAGS_DESCRIPTION: 'Organise your flags with tags, tagging your features as "<strong>protected</strong>" will prevent them from accidentally being deleted.',
        HIDE_FROM_SDKS_DESCRIPTION: 'Enable this if you want to prevent the Flagsmith API from returning this feature regardless of if it is enabled. Use this if you don\'t want users to see that a feature name whilst it is in development.',
        SEGMENT_OVERRIDES_DESCRIPTION: 'Set different values for your feature based on what segments users are in.',
        ENVIRONMENT_OVERRIDE_DESCRIPTION: (name:string) => `Features are created once per project<br/>but their <strong>value</strong> and <strong>enabled state</strong> are set per environment.<br/>Saving this feature will override the <strong>${name}</strong> environment.`,
        IDENTITY_OVERRIDES_DESCRIPTION: 'See which identities have specific overridden values for this feature.<br/>Identity overrides take priority over segment overrides and environment values.',
        REMOTE_CONFIG_DESCRIPTION: 'Features can have values as well as being simply on or off, e.g. a font size for a banner or an environment variable for a server.',
        REMOTE_CONFIG_DESCRIPTION_VARIATION: 'Features can have values as well as being simply on or off, e.g. a font size for a banner or an environment variable for a server.<br/>Variation values are set per project, the environment weight is per environment.',
        FEATURE_FLAG_DESCRIPTION: 'A feature that you can turn on or off per environment or user, e.g. instant messaging for a mobile app or an endpoint for an API.',
        AUDIT_WEBHOOKS_DESCRIPTION: 'Receive a webhook for when an audit log is received.',
        WEBHOOKS_DESCRIPTION: 'Receive a webhook for when feature values are changed.',
        USER_PROPERTY_DESCRIPTION: 'The name of the user trait or custom property belonging to the user, e.g. firstName',
        ORGANISATION_DESCRIPTION: 'This is used to create a default organisation for team members to create and manage projects.',
        ENVIRONMENT_DESCRIPTION: 'Environments are versions of your projects, environments within a project all share the same features but can be individually turned on/off or have different values.',
    },
    codeHelp: {
        keys: {
            'Java': 'java',
            'JavaScript': 'javascript',
            'React Native': 'javascript',
            'Node JS': 'javascript',
        },

        'CREATE_USER': (envId:string, userId:string = keywords.USER_ID) => ({
            '.NET': require('./code-help/create-user/create-user-dotnet')(envId, keywords),
            'curl': require('./code-help/create-user/create-user-curl')(envId, keywords, userId),
            'Flutter': require('./code-help/create-user/create-user-flutter')(envId, keywords),
            'Go': require('./code-help/create-user/create-user-go')(envId, keywords),
            'iOS': require('./code-help/create-user/create-user-ios')(envId, keywords),
            'Java': require('./code-help/create-user/create-user-java')(envId, keywords),
            'JavaScript': require('./code-help/create-user/create-user-js')(envId, keywords),
            'Next.js': require('./code-help/create-user/create-user-next')(envId, keywords),
            'Node JS': require('./code-help/create-user/create-user-node')(envId, keywords),
            'PHP': require('./code-help/create-user/create-user-php')(envId, keywords),
            'Python': require('./code-help/create-user/create-user-python')(envId, keywords),
            'React': require('./code-help/create-user/create-user-react')(envId, keywords),
            'React Native': require('./code-help/create-user/create-user-rn')(envId, keywords),
            'Ruby': require('./code-help/create-user/create-user-ruby')(envId, keywords),
            'Rust': require('./code-help/create-user/create-user-rust')(envId, keywords),
        }),

        'USER_TRAITS': (envId:string, userId?:string) => ({
            '.NET': require('./code-help/traits/traits-dotnet')(envId, keywords, userId),
            'curl': require('./code-help/traits/traits-curl')(envId, keywords, userId),
            'Flutter': require('./code-help/traits/traits-flutter')(envId, keywords, userId),
            'Go': require('./code-help/traits/traits-go')(envId, keywords, userId),
            'iOS': require('./code-help/traits/traits-ios')(envId, keywords, userId),
            'Java': require('./code-help/traits/traits-java')(envId, keywords, userId),
            'JavaScript': require('./code-help/traits/traits-js')(envId, keywords, userId),
            'Next.js': require('./code-help/traits/traits-next')(envId, keywords, userId),
            'Node JS': require('./code-help/traits/traits-node')(envId, keywords, userId),
            'PHP': require('./code-help/traits/traits-php')(envId, keywords, userId),
            'Python': require('./code-help/traits/traits-python')(envId, keywords, userId),
            'React': require('./code-help/traits/traits-react')(envId, keywords, userId),
            'React Native': require('./code-help/traits/traits-rn')(envId, keywords, userId),
            'Ruby': require('./code-help/traits/traits-ruby')(envId, keywords, userId),
            'Rust': require('./code-help/traits/traits-rust')(envId, keywords, userId),
        }),

        'INIT': (envId:string) => ({
            '.NET': require('./code-help/init/init-dotnet')(envId, keywords),
            'curl': require('./code-help/init/init-curl')(envId, keywords),
            'Flutter': require('./code-help/init/init-flutter')(envId, keywords),
            'Go': require('./code-help/init/init-go')(envId, keywords),
            'iOS': require('./code-help/init/init-ios')(envId, keywords),
            'Java': require('./code-help/init/init-java')(envId, keywords),
            'JavaScript': require('./code-help/init/init-js')(envId, keywords),
            'Next.js': require('./code-help/init/init-next')(envId, keywords),
            'Node JS': require('./code-help/init/init-node')(envId, keywords),
            'PHP': require('./code-help/init/init-php')(envId, keywords),
            'Python': require('./code-help/init/init-python')(envId, keywords),
            'React Native': require('./code-help/init/init-rn')(envId, keywords),
            'React': require('./code-help/init/init-react')(envId, keywords),
            'Ruby': require('./code-help/init/init-ruby')(envId, keywords),
            'Rust': require('./code-help/init/init-rust')(envId, keywords),
        }),

        'INSTALL': {
            '.NET': require('./code-help/install/install-dotnet')(keywords),
            'curl': require('./code-help/install/install-curl')(keywords),
            'Flutter': require('./code-help/install/install-flutter')(keywords),
            'Go': require('./code-help/install/install-go')(keywords),
            'Java': require('./code-help/install/install-java')(keywords),
            'JavaScript': require('./code-help/install/install-js')(keywords),
            'Next.js': require('./code-help/install/install-js')(keywords),
            'Node JS': require('./code-help/install/install-node')(keywords),
            'PHP': require('./code-help/install/install-php')(keywords),
            'Python': require('./code-help/install/install-python')(keywords),
            'React Native': require('./code-help/install/install-rn')(keywords),
            'React': require('./code-help/install/install-js')(keywords),
            'Ruby': require('./code-help/install/install-ruby')(keywords),
            'Rust': require('./code-help/install/install-rust')(keywords),
            'iOS': require('./code-help/install/install-ios')(keywords),
        },
    },
    simulate: {},
    roles: {
        'ADMIN': 'Organisation Administrator',
        'USER': 'User',
    },
    exampleWebhook: `{
 "data": {
  "changed_by": "Ben Rometsch",
  "new_state": {
   "enabled": true,
   "environment": {
    "id": 23,
    "name": "Development"
   },
   "feature": {
    "created_date": "2021-02-10T20:03:43.348556Z",
    "default_enabled": false,
    "description": "Show html in a butter bar for certain users",
    "id": 7168,
    "initial_value": null,
    "name": "butter_bar",
    "project": {
     "id": 12,
     "name": "Flagsmith Website"
    },
    "type": "CONFIG"
   },
   "feature_segment": null,
   "feature_state_value": "<strong>\\nYou are using the develop environment.\\n</strong>",
   "identity": null,
   "identity_identifier": null
  },
  "previous_state": {
   "enabled": false,
   "environment": {
    "id": 23,
    "name": "Development"
   },
   "feature": {
    "created_date": "2021-02-10T20:03:43.348556Z",
    "default_enabled": false,
    "description": "Show html in a butter bar for certain users",
    "id": 7168,
    "initial_value": null,
    "name": "butter_bar",
    "project": {
     "id": 12,
     "name": "Flagsmith Website"
    },
    "type": "CONFIG"
   },
   "feature_segment": null,
   "feature_state_value": "<strong>\\nYou are using the develop environment.\\n</strong>",
   "identity": null,
   "identity_identifier": null
  },
  "timestamp": "2021-06-18T07:50:26.595298Z"
 },
 "event_type": "FLAG_UPDATED"
}`,
    exampleAuditWebhook: `{
 "created_date": "2020-02-23T17:30:57.006318Z",
 "log": "New Flag / Remote Config created: my_feature",
 "author": {
  "id": 3,
  "email": "user@domain.com",
  "first_name": "Kyle",
  "last_name": "Johnson"
 },
 "environment": null,
 "project": {
  "id": 6,
  "name": "Project name",
  "organisation": 1
 },
 "related_object_id": 6,
 "related_object_type": "FEATURE"
}`,
};
